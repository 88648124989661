import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import web from "../../assets/images/services/web.jpeg"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="Web Development Outsourcing Company in India"
                description="We have been outsourcing web development projects to India for the past 15 years, and we have completed more than 90 thousand projects with a value of more than one hundred million dollars." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={web} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>Web Application Development</h1>
                        <p>
                            Web applications are an essential part of modern business operations, enabling companies to deliver services and interact with customers online. At OurCompany, we specialize in developing scalable, secure, and user-friendly web applications tailored to your specific business needs. Whether you need a simple website, a complex web portal, or an e-commerce platform, our team of experienced developers will work closely with you to deliver a solution that exceeds your expectations.
                        </p>
                    </div>
                </div>
                <div className="service-sub-container">
                    Our approach to web application development involves:
                    <ul >
                        <li className="mt-2"><strong>Requirement Analysis:</strong> We start by understanding your business requirements and objectives to define the scope of the project.</li>
                        <li className="mt-2"><strong>Design:</strong> Our team of designers creates intuitive and visually appealing user interfaces that enhance the user experience.</li>
                        <li className="mt-2"><strong>Development:</strong> Using the latest web technologies and frameworks such as React, Angular, or Vue.js, we develop robust and scalable web applications that meet your business needs.</li>
                        <li className="mt-2"><strong>Testing:</strong> We perform comprehensive testing to ensure that your web application is bug-free, secure, and performs well under various conditions.</li>
                        <li className="mt-2"><strong>Deployment:</strong> Once the web application is ready, we deploy it to your desired hosting environment and provide ongoing support and maintenance to keep it running smoothly.</li>
                    </ul>
                </div>



                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query WebDevelopmentPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
